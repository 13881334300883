import React, { FC } from 'react';
import { DefaultTemplate } from 'templates';
import { Cell, Grid, SectionContainer } from 'components/Grid';
import { Heading } from 'components/Layout';
import { ContactButton } from 'components/Buttons';
import { Link } from 'components/helpers';

import styles from 'scss/landing-page.module.scss';
import gridStyles from 'scss/grid.module.scss';

const LandingPage: FC = () => {
  return (
    <DefaultTemplate
      pageTitle='Salary Negotiation - Software Engineering Interview Preparation'
      metaDescription='Learn how to negotiate the best possible salary for your next job offer with our one-hour crash course. Gain valuable insights and techniques to help you negotiate with confidence and achieve the salary you deserve.'
    >
      <div className={styles.pageMain}>
        <SectionContainer name='salary-negotiation'>
          <Grid margin={'-15px'} columns={12}>
            <Cell padding={15} width={{ xs: 12 }}>
              <p>
                <Link to='/interview-preparation'>← Back to overview</Link>
              </p>
              <Heading tagLevel={1} classes={gridStyles.marginTop0}>
                <strong>Salary Negoation</strong>
              </Heading>
              <Heading tagLevel={2} classes={gridStyles.marginTop0}>
                Overview
              </Heading>
              <p>
                Expecting an offer? Don't leave money on the table! In this
                one-hour crash course, you will learn the skills and strategies
                necessary to negotiate the best possible salary for your next
                job offer. We will identify common negotiation pitfalls and
                provide you with a framework for negotiating confidently and
                effectively.
              </p>
              <Heading tagLevel={2} classes={gridStyles.marginTop0}>
                Session - 1 hour
              </Heading>
              <p>
                We will cover topics such as researching market salaries,
                identifying your value, creating a negotiation plan, and
                handling counteroffers. You will gain valuable insights and
                techniques to help you negotiate with confidence and achieve the
                salary you deserve.
              </p>
              <Heading tagLevel={2} classes={gridStyles.marginTop0}>
                Results to expect
              </Heading>
              <p>
                By the end of the session, you will have a clear understanding
                of the negotiation process and the skills necessary to succeed
                in any salary negotiation. This training will help you become a
                more confident negotiator, equipped with the knowledge and
                skills necessary to secure a competitive salary.
              </p>
              <div className={gridStyles.textCenter}>
                <ContactButton>Email to schedule</ContactButton>
              </div>
            </Cell>
          </Grid>
        </SectionContainer>
      </div>
    </DefaultTemplate>
  );
};

export default LandingPage;
